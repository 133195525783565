import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faBusinessTime,
    faHeart,
    faPhoneAlt
} from '@fortawesome/free-solid-svg-icons'

import { Container } from './styles'
import LogoDankiSVG from '../../assets/images/logo-danki-code.svg'

import {
    faFacebookF,
    faInstagram,
    faYoutube
} from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { Facebook, Heart, Instagram, Mail, Youtube } from 'lucide-react'

export function Footer() {
    return (
        <Container>
            <div className="container">
                <article className={'about'}>
                    <header>
                        <h1>Sobre a Danki Code</h1>
                    </header>
                    <p>
                        A Danki Code é uma empresa de desenvolvimento web que também atua na
                        área de cursos online voltados ao assunto. Sediada em Florianópolis,
                        desenvolve soluções inovadoras para empresas formando profissionais
                        capacitados e completos para o mercado de Tecnologia.
                    </p>
                    <p>
                        Plataforma Desenvolvida com <Heart size={16} /> por
                        Danki Code.
                    </p>
                    <p>
                        É proibida a reprodução ou distribuição do conteúdo publicado aqui.
                    </p>
                    <div>
                        <a
                            href="https://www.instagram.com/grupodankicodeoficial"
                            rel={'noreferrer'}
                            target={'_blank'}
                            title={'Instagram Danki Code Oficial'}
                        >
                            <Instagram size={24} />
                        </a>
                        <a
                            href="https://www.youtube.com/grupodankicodeoficial"
                            rel={'noreferrer'}
                            target={'_blank'}
                            title={'Youtube Danki Code Oficial'}
                        >
                            <Youtube size={24} />
                        </a>
                        <a
                            href="https://www.facebook.com/grupodankicodeoficial"
                            rel={'noreferrer'}
                            target={'_blank'}
                            title={'Facebook Danki Code Oficial'}
                        >
                            <Facebook size={24} />
                        </a>
                    </div>
                </article>
                <article className={'support'}>
                    <header>
                        <h1>Atendimento ao cliente</h1>
                    </header>
                    <p>
                        <Mail size={16} /> <strong>E-mail:</strong>{' '}
                        atendimento@dankicode.com
                    </p>
                    {/*<p>*/}
                    {/*    <FontAwesomeIcon icon={faPhoneAlt}/>{' '}*/}
                    {/*    <strong>Telefone/WhatsApp:</strong> (48) 99807-5162*/}
                    {/*</p>*/}
                    {/*<p>*/}
                    {/*    <FontAwesomeIcon icon={faBusinessTime}/> De segunda a domingo*/}
                    {/*</p>*/}
                </article>
            </div>

            <div className="bottom">
                {'<'}
                <img src={LogoDankiSVG} alt="Danki Code & Danki Club" />
                {'>'}
            </div>
        </Container>
    )
}
